<template>
    <div class="h-80">
        <div class="flex justify-between items-center mb-6 border-b pb-6">
            <div>
                <h1 class="text-4xl">Deposit</h1>
                <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
            </div>
        </div>

        <div class="flex flex-col">
            <div class="flex flex-wrap overflow-hidden">
                <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
                    <!-- Column Content -->
                    <div class="mb-4">
                        <button
                            @click="depositType = 'cx'"
                            class="
                                w-full
                                border-2 border-blue-300
                                py-4
                                rounded-md
                                hover:bg-blue-50
                            "
                            :class="{ 'bg-blue-50': depositType === 'cx' }"
                        >
                            Chapman Xpress AGENT
                        </button>
                    </div>
                </div>

                <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
                    <!-- Column Content -->
                    <div class="mb-4">
                        <button
                            @click="depositType = 'tx'"
                            class="
                                w-full
                                border-2 border-blue-300
                                py-4
                                rounded-md
                                hover:bg-blue-50
                            "
                            :class="{ 'bg-blue-50': depositType === 'tx' }"
                        >
                            TX AGENT
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="w-full h-full border-t py-9 text-center text-gray-500"
                v-if="!depositType"
            >
                Select Deposit Type
            </div>

            <CXDeposit :user="user" v-if="depositType === 'cx'" />
            <TXdeposit :user="user" v-if="depositType === 'tx'" />
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';

import CXDeposit from './Types/CXDeposit';
import TXdeposit from './Types/TXdeposit';
// import BranchSales from "../../components/Charts/BranchSales";

export default {
    middleware: 'auth',

    layout: 'default',
    components: {
        CXDeposit,
        TXdeposit,
    },

    data: () => ({
        isLoading: false,
        depositType: null,
    }),

    computed: mapGetters({
        user: 'auth/user',
    }),

    mounted() {
        if (this.user.data.worksheet_status) {
            this.$router.go(-1);
            Swal.fire({
                icon: 'warning',
                title: 'Worksheet already generated!',
                text: 'Please note that after generating a worksheet you are not allowed to make sales',
            });
            return false;
        }
    },

    methods: {
        async onSubmit() {
            // Submit the form
            this.isLoading = true;

            try {
                await this.form.post('/branch/store');

                this.isLoading = false;
                this.$router.push({ path: '/branches' });

                return true;
            } catch (e) {
                this.isLoading = false;

                console.log('Error');
                return false;
            }
        },
    },
};
</script>
